import React, { useEffect, useState } from "react"
import Layout from "../../../../components/Layout"
import Hero from "../../../../components/Hero"
import Breadcrumb from "../../../../components/Breadcrumb"
import Searcher from "../../../../components/Searcher"
import Carousel from "../../../../components/Carousel"
import SocialBtn from "../../../../components/SocialButton"
import Pagination from "../../../../components/Pagination"
import { Link } from "gatsby"
import Gallery from "../../../../components/Gallery"
import { navigate } from "gatsby"
import { useAxiosClient } from "../../../../service/useAxiosClient"
import TopicalityMenu from "../../../../components/TopicalityPage/TopicalityMenu"
import Seo from "../../../../components/TitlePage"
import ImgWithLightBox from "../../../../components/ImgWithLightBox"

export default function GalleryFile({ params = {}, pageContext = {}, location }) {
  const id = pageContext.id || params.id
  const [images, setImages] = useState([])
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Galerías de imágenes", to: "/actualidad/galerias" },
    { label: "Asamblea EFPA España 20" },
  ]

  const [{ data: galleryData = {} }] = useAxiosClient({
    url: `/galleries/${id}/web/`,
    method: "GET",
  })

  useEffect(() => {
    if (Array.isArray(galleryData.images))
      setImages(
        galleryData.images.map(item => ({
          alt: item.title,
          src: item.image,
          title: item.title,
        }))
      )
  }, [galleryData])

  const [currentPage, setCurrentPage] = useState(1)
  const [value, setValue] = useState(0)

  const [{ data: galleriesData = {} }] = useAxiosClient({
    url: "/galleries/web/",
    method: "GET",
    params: {
      page: currentPage,
    },
  })

  const getPage = page => {
    setCurrentPage(page)
  }

  const selectSlide = slide => {
    setValue(slide)
  }

  const [menuList, setMenuList] = useState([])

  const [{ data: dataMenu }] = useAxiosClient({
    url: "/publications/categories/news/",
    method: "GET",
  })

  useEffect(() => {
    if (dataMenu && dataMenu.length) {
      setMenuList(dataMenu)
    }
  }, [dataMenu])

  const getRoute = data =>
    navigate(data.link ? data.link : `/actualidad/${data.pk}/${data.slug}`)

  const renderNews = () =>
    Array.isArray(galleryData.related_news) &&
    galleryData.related_news.map((item, key) => (
      <div className="GalleryFile-relatedNew">
        <span className="GalleryFile-date">
          {item.publication_date} -{" "}
          <a href={`/actualidad/${item.category.pk}/${item.category.slug}`}>
            {item.category.title}
          </a>
        </span>
        <Link
          className="GalleryFile-link"
          to={`/actualidad/${item.category.pk}/${item.category.slug}/${item.pk}/${item.slug}`}
        >
          {item.title}
        </Link>
      </div>
    ))

  const renderImage = () => {
    return (
      Array.isArray(galleriesData.items) &&
      galleriesData.items.map(item => {
        return (
          <div className="col-12 col-md-4 p-0 position-relative">
            <Gallery
              image={item.image}
              text={item.title}
              quantity={item.num_images}
              link={`/actualidad/galerias/${item.pk}/${item.slug}`}
            />
            {item.pk === galleryData.pk && (
              <div className="GalleryFile-currentGallery">Galería actual</div>
            )}
          </div>
        )
      })
    )
  }

  return (
    <Layout>
      <Hero image="/imgs/actualidad-banner.jpg" text="Galerías de imágenes" />
      <Seo
        title={pageContext.title}
        description={pageContext.description}
        image={pageContext.image}
        url={location.pathname}
      />
      <div className="container950">
        <Breadcrumb path={path} />
        <Searcher />
        <div className="row efpa-row">
          <div className="col-12 col-md-3 Topicality-padding-col">
            <TopicalityMenu
              list={menuList}
              showTitle={false}
              getCategory={getRoute}
              initialCategory={-2}
              useAllData
            />
          </div>
          <div className="col-12 col-md-9 Topicality-col-9">
            <span className="GalleryFile-title">{galleryData.title}</span>
            <Carousel
              className="GalleryFile-carousel overflow-hidden"
              // slides={images}
              thumbnails={images}
              onChange={selectSlide}
              value={value}
              itemWidth={700}
            >
              <ImgWithLightBox
                images={images}
                preview="selected"
                selected={value}
                onChange={selectSlide}
                manual
                multiple
              />
            </Carousel>
            <div className="GalleryFile-social">
              <SocialBtn
                icon="fab fa-facebook-f"
                className="w-100 mr-1"
                backgroundColor="#42519d"
                link="/"
              />
              <SocialBtn
                icon="fa-brands fa-x-twitter"
                className="w-100 ml-1"
                backgroundColor="#000000"
                link="/"
              />
            </div>
            {galleryData?.related_news && (
              <div className="GalleryFile-relatedNews">
                <span className="GalleryFile-subtitle">
                  Noticias relacionadas:
                </span>
                {renderNews()}
              </div>
            )}
            <span className="GalleryFile-subtitle">
              Galerías de imágenes EFPA:
            </span>
            <div className="GalleryFile-gallery">
              <Pagination
                currentPage={currentPage}
                totalPages={galleriesData.total_pages}
                getPage={getPage}
              />
              <div className="row efoa-row">{renderImage()}</div>
              <Pagination
                currentPage={currentPage}
                totalPages={galleriesData.total_pages}
                getPage={getPage}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
