import React, { Fragment } from "react"
import PropTypes from "prop-types"
import CollapseMenu from "../Collapse/CollapseMenu"
import Hours from "../Hours"

const TopicalityMenu = props => {
  return (
    <Fragment>
      <CollapseMenu {...props} title="Menú" />
      <div className="d-none d-md-block">
        <Hours
          useGalleryItem
          {...props}
          showTitle={false}
          className="LayoutTest-options"
        />
      </div>
    </Fragment>
  )
}

TopicalityMenu.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  selected: PropTypes.string,
  useGalleryItem: PropTypes.bool,
  useAllData: PropTypes.bool,
  initialCategory: PropTypes.number,
}

export default TopicalityMenu
