import React, { Fragment } from "react"
import Proptypes from "prop-types"
import { Link } from "gatsby"
// import { Modal, ModalBody } from "reactstrap"
import ButtonAsLink from "../ButtonAsLink"

export default function Gallery(props) {
  const {
    className,
    image,
    text,
    quantity,
    link,
    type,
    id,
    selected,
    // toggle,
    // open,
  } = props

  const renderVideoStatus = () => {
    if (!selected) {
      return <i className="fas fa-caret-right"></i>
    }
    return (
      <span className={selected ? "Gallery-selected" : "d-none"}>
        Video actual
      </span>
    )
  }

  const renderType = () => {
    if (type !== "video") {
      return (
        <div className="Gallery-image">
          <img src={image} alt="" />
        </div>
      )
    }
    return (
      <div className="Gallery-image video">
        <img src={image} alt="" />
        {renderVideoStatus()}
      </div>
    )
  }
  const renderLink = () => {
    if (!link) {
      return (
        <ButtonAsLink className="Gallery-link">
          {renderType()}
          <span className={`Gallery-text ${type}`}>{text}</span>
          <span
            className={type === "video" ? `d-none` : `Gallery-quantity`}
          >{`(${quantity} fotos)`}</span>
        </ButtonAsLink>
      )
    }
    return (
      <Link state={{ id: id }} to={link} className="Gallery-link">
        {renderType()}
        <span className={`Gallery-text ${type}`}>{text}</span>
        <span
          className={type === "video" ? `d-none` : `Gallery-quantity`}
        >{`(${quantity} fotos)`}</span>
      </Link>
    )
  }
  return (
    <Fragment>
      <div className={`Gallery ${type || ""} ${className}`}>{renderLink()}</div>
      {/* <Modal
        isOpen={open}
        toggle={toggle}
        contentClassName="VideoLightBox-content"
        backdropClassName="VideoLightBox-modalBackground"
        centered
        scrollable
      >
        <ModalBody>{parse(videoTab?.embed_code)}</ModalBody>
      </Modal> */}
    </Fragment>
  )
}

Gallery.propTypes = {
  className: Proptypes.string,
  image: Proptypes.string,
  text: Proptypes.string,
  quantity: Proptypes.string,
  link: Proptypes.string,
}

Gallery.defaultProps = {
  className: "",
  image: "",
  text: "",
  quantity: "",
}
