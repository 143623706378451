import React from "react"
import PropTypes from "prop-types"

const ButtonAsLink = props => {
  const { text, className } = props
  return (
    <button {...props} className={`ButtonAsLink ${className || ""}`}>
      {text ? text : props.children}
    </button>
  )
}

ButtonAsLink.propTypes = {
  text: PropTypes.string,
}

export default ButtonAsLink
