import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"

const Pagination = props => {
  const { totalPages, getPage, className } = props
  const [currentPage, setCurrentPage] = useState(props.currentPage)

  useEffect(() => {
    setCurrentPage(props.currentPage)
  }, [props.currentPage])

  const getCurrentPage = page => () => {
    setCurrentPage(page)

    if (getPage && typeof getPage === "function") {
      getPage(page)
    }
  }

  const renderItems = () => {
    if (totalPages <= 0) return

    let items = []

    let index = currentPage > 10 ? currentPage - 5 : 1
    let lastItem = index === 1 ? 11 - currentPage : 4

    if (totalPages === currentPage && totalPages > 10) {
      index = totalPages === currentPage ? totalPages - 10 : index
    }

    if (index !== 1) {
      items = [
        ...items,
        <div className="col-1">
          <div className={`Pagination-more`}>...</div>
        </div>,
      ]
    }

    for (
      ;
      index <= currentPage + lastItem && index <= totalPages && index >= 0;
      index++
    ) {
      items = [
        ...items,
        <button
          className={`col-1 Pagination-item ${
            currentPage === index ? "selected" : ""
          }`}
          onClick={getCurrentPage(index)}
        >
          {index}
        </button>,
      ]

      if (index === currentPage + lastItem && index < totalPages) {
        items = [
          ...items,
          <div className="col-1" key={`pagination-${uuidv4()}`}>
            <div className="Pagination-more">...</div>
          </div>,
        ]
      }
    }

    return items
  }

  const renderLeftButton = () => {
    if (currentPage === 1) return

    return (
      <button
        className={`Pagination-button left`}
        onClick={getCurrentPage(currentPage - 1)}
      >
        <i class="fas fa-caret-left"></i>
      </button>
    )
  }

  const renderRighthButton = () => {
    if (currentPage === totalPages) return

    return (
      <button
        className="Pagination-button right"
        onClick={getCurrentPage(currentPage + 1)}
      >
        <i className="fas fa-caret-right"></i>
      </button>
    )
  }

  return (
    <div className={`Pagination ${className}`}>
      <div className="row">
        <div className="col-6 col-md-1">{renderLeftButton()}</div>
        <div className="col-10 d-none d-md-block">
          <div className="row">{renderItems()}</div>
        </div>
        <div className="col-6 col-md-1">{renderRighthButton()}</div>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  getPage: PropTypes.func,
}

Pagination.propTypes = {
  className: "",
}

export default Pagination
